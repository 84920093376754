import React from 'react';
import Link from 'next/link';
import './SectionInvest.scss';
import './mobile/index.scss';
import { Container, Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { Trans, withTranslation } from '@i18n';
import { vipBg2, joint_star } from '@assets/img';
import { Polygon, LazyBackground } from '../index';
import { languageReturner } from '../../helpers';

type Props = {
    windowWidth?: any;
    language?: string;
    title?: string;
    showBlocks?: any[];
    blocks?: any;
    t?: any;
    blocksOptions?: any
};
const SectionInvest = (props: Props) => {
  const { t } = props;
  const click = function (event: any) {
    event.preventDefault();
  };
  const {
    title = 'На чем еще можно заработать?',
    windowWidth,
    language,
    showBlocks,
    blocks,
    blocksOptions,
  } = props;
  const mergeBlocksOptions = (blocksOptions: any, additionalBlocksOptions: any) => {
    const mergedOptions: {[index:string]:any} = {};
    if (!additionalBlocksOptions) return blocksOptions;
    Object.entries(blocksOptions).map(([key, val]: any) => {
      mergedOptions[key] = { ...blocksOptions[key], ...(() => (additionalBlocksOptions[key] ? additionalBlocksOptions[key] : {}))() };
    });
    return mergedOptions;
  };
  const data = {
    sectionTitle: title,
    showBlocks: showBlocks || ['miners', 'rent', 'share'],
    blocks: blocks || {
      rent: {
        title: 'Аренда мощностей',
        isdisabled: false,
        description: 'Арендуйте наши майнеры и получайте криптовалюту каждый день. Майнеры наши – прибыль ваша.',
        blockProps: {},
        link: {
          component: Link,
          props: {
            href: '/cloudmining',
            as: `${languageReturner(language)}/cloudmining`,
          },
        },
        linkText: <>
          <span>{t('Арендовать')}</span>
          <i className="fal fa-angle-right" />
        </>,
      },
      miners: {
        title: 'Купить майнер',
        isdisabled: false,
        description: 'Получите собственный майнер. Наши инженеры УЖЕ его устанавливают, настраивают и будут следить за ним круглосуточно. ',
        blockProps: {},
        link: {
          component: Link,
          props: {
            href: '/miners',
            as: `${languageReturner(language)}/miners`,
          },
        },
        linkText: <>
          <span>{t('Приобрести')}</span>
          <i className="fal fa-angle-right" />
        </>,
      },
      share: {
        title: 'Совместные покупки',
        isdisabled: false,
        description: 'Покупайте мощности вместе с другими пользователями CryptoUniverse по оптовой цене без дополнительных комиссий и получайте доход неограниченное время',
        blockProps: {
          className: 'section-invest-old__joint-card',
        },
        link: {
          component: Link,
          props: {
            href: '/joint-participation',
            as: `${languageReturner(language)}/joint-participation`,
          },
        },
        linkText: <>
        <span>{t('Присоединиться')}</span>
        <i className="fal fa-angle-right" />
      </>,
      },
    },
  };
  return (
    <LazyBackground className="section-invest-old" src={vipBg2}>
      <Container>
        <h3>{t(data.sectionTitle)}</h3>
        <Grid container spacing={4} className="section-invest-old-flex-block">
          {data.showBlocks.map((block) => {
            const {
              title, description, link, linkText, isdisabled, blockProps,
            } = mergeBlocksOptions(data.blocks, blocksOptions)[block];
            const LinkComponent = link.component;
            return (
              <Grid
                key={title}
                item
                xs={12}
                {...blockProps}
                sm={(windowWidth <= 768) ? 12 : 4}
                md={props.showBlocks ? 6 : 4}
                style={isdisabled ? { opacity: '0.8', position: 'relative', pointerEvents: 'none' } : {}}
              >
                {isdisabled === true ? (
                  <div className="section-invest-old__soon-text">
                    <Trans>Скоро</Trans>
                  </div>
                ) : null}
                <LinkComponent {...link.props}>
                  <a
                    className="news_decoration_none"
                    onClick={(e) => {
                      if (isdisabled) {
                        click(e);
                      }
                    }}
                  >
                    <Polygon
                      cornerRadius={25}
                      cornerPosition="rightTop"
                      bgColor="#fff"
                      border={title === 'Совместные покупки' ? { width: 4, color: '#FFE27A' } : {}}
                      className="section-invest-old__card"
                      boxShadow={{
                        x: 0, y: 2, blurRadius: 22, color: 'rgba(74, 74, 74, 0.0908818)',
                      }}
                    >
                      <Paper className={`paper pa4 pb3 pt0 section-invest-old-block main-page-cards ${title === 'Совместные покупки' ? 'section__joint' : ''}`}>
                        <h4 className="section-invest-old-title">{t(title)}</h4>
                        {block === 'share' && (
                          <Polygon
                            cornerRadius={5}
                            cornerPosition="rightBottom"
                            className="joint-icon__polygon"
                            bgColor="#FFE27A"
                          >
                            <div className="joint-icon__inner">
                              <img src={joint_star} alt="joint_star" />
                              <Trans>Новое</Trans>
                            </div>
                          </Polygon>
                        )}
                        <div className={`section-invest-old-description ${block === 'share' ? 'section-invest-old-description_joint' : ''}`}>
                          {t(description)}
                        </div>
                        <span className="section-invest-old-link">
                          {linkText}
                        </span>
                      </Paper>
                    </Polygon>
                  </a>
                </LinkComponent>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </LazyBackground>
  );
};
SectionInvest.getInitialProps = async ({
  req,
}: any) => ({
  namespacesRequired: ['common'],
});
function mapStateToProps(state: any) {
  const { windowWidth, language } = state.info;
  return {
    windowWidth,
    language,
  };
}
export default withTranslation('common')(connect(mapStateToProps)(React.memo(SectionInvest)));
